import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {
  AfterViewInit,
  Component,
  Inject,
  PLATFORM_ID,
  inject
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { GoogleTagManagerService } from './google-tag-manager';

// 'es' Spanish is our default language
registerLocaleData(localeEs, 'es');

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}
@Component({
  selector: 'vaki-root',
  template: `<router-outlet></router-outlet>`,
  standalone: true,
  imports: [RouterModule]
})
export class AppComponent implements AfterViewInit {
  private readonly router = inject(Router);

  constructor(
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startLoggingGtagManager();
      this.initIntercom();
    }
  }

  initIntercom(): void {
    window.Intercom('boot', {
      app_id: 'iagcjkqo'
    });
  }

  startLoggingGtagManager(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
