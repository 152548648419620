/** This environment is used for development in vaquinha-dev.web.app.
 * Use it with flag the --configuration=development */
import {
  APP_VERSION,
  Environment,
  Environments
} from './environment.interface';
const env = Environments.STAGING;
export const environment: Environment = {
  env,
  appVersion: `${APP_VERSION}-${env}`,
  useEmulators: false,
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAHplWWdLfuRRoqXhB7FTScwI65X6O3cUg',
    authDomain: 'auth-dev.vaki.co',
    databaseURL: 'https://vaquinha-dev.firebaseio.com',
    projectId: 'vaquinha-dev',
    storageBucket: 'vaquinha-dev.appspot.com',
    messagingSenderId: '393842000015',
    appId: '1:393842000015:web:f07a73bd0c826eb9',
    measurementId: 'G-64B4H9YQDQ'
  },
  mercadopago: {
    key: 'TEST-2f614465-ac25-4aa6-8a30-8a86add1da9b',
    notification_url:
      'https://us-central1-vaquinha-dev.cloudfunctions.net/mercadopagoWebhookEndpoint'
  },
  stripe_key: 'pk_test_b  lonqhVhrnQit15Krl5n4gz7',
  stripe_key_mx:
    'pk_test_51Mz3HYFv0YLOxoQnpa5rJzaU9puI8dywDYQ2TtUOqbiAGn7ZH07Esxmzw51uqIgYEyPCdI8wxefXvbOKa5Ba2eLT00fjNPs1Jz',
  hostUrl: 'https://staging.vaki.co',
  apiVakiURL: 'https://us-central1-vaquinha-dev.cloudfunctions.net/api',
  adminUid: 'f5P1J1xTXobgga3rbGJ0lHXC29n1',
  adminEmail: 'gabriela@vaki.co',
  froalaKey: '5OA4gG5E3E3G3B4A2D-13uenoD3kfvC2B1F1G1B4D4G3B1B8C6==',
  algolia: {
    appId: 'YAKUXKWQES',
    apiKey: '3c0ab8160d7b540a173380366288d456',
    indexes: {
      vakis: 'dev_vakis'
    }
  },
  googleTagManager: {
    id: 'GTM-KXG9PGW',
    auth: 'mlr6ba24QuxPk6VatbxHew',
    env: 'env-39'
  },
  featureFlagsDefault: {
    'vaki-sites': true,
    'vaki-gifts': true,
    'vaki-sponsors': true,
    'algolia-recommend': false
  },
  recaptcha: {
    siteKey: '6Lco_FskAAAAAG7MjzpCD5gTo9XRasdJwJzcbpWv',
    appCheckDebugToken: 'fd2f99dd-8fe4-4adf-8037-c9d1ea56cd7f'
  },
  builderIo: 'eb7a8f96dc7d4d8c9d0841e89b2ecbd8'
};
