import {
  ApplicationConfig,
  isDevMode,
  importProvidersFrom
} from '@angular/core';
import { provideAnalytics } from '@angular/fire/analytics';
import { provideAppCheck } from '@angular/fire/app-check';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { getAnalytics } from 'firebase/analytics';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck
} from 'firebase/app-check';
import { environment } from '../environments/environment';
import { GoogleTagManagerModule } from './google-tag-manager';

export const browserConfig: ApplicationConfig = {
  providers: [
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true
    }),
    importProvidersFrom(
      GoogleTagManagerModule.forRoot({
        id: environment.googleTagManager.id,
        gtm_auth: environment.googleTagManager.auth,
        gtm_preview: environment.googleTagManager.env
      })
    ),
    provideAnalytics(() => getAnalytics()),
    provideAppCheck(() => {
      if (!environment.production) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
          environment.recaptcha.appCheckDebugToken;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.recaptcha.siteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true
      });
    })
  ]
};
